
@tailwind base;
@tailwind components;
@tailwind utilities;


@import url('https://fonts.googleapis.com/css2?family=Rubik+Mono+One&display=swap');/*

/* Global reset */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.AppBackground{
  background-color: rgb(44, 57, 22);
}

.App {
  text-align: left;
  margin-left: 13%;
  margin-right: 13%;
  padding:3%;
  background-color: rgb(44, 57, 22);
  height: 100vh;
}

.App-header {
  /*background-color: #282c34;*/
  color: white;
  font-weight: bold;
  font-size: large;
}

.App-sub-header{
  display: flex;
  text-decoration: underline;
  color:rgb(205, 29, 29);
  margin-top: 5px;
  font-size:medium;
}

.App-sub-header :nth-child(2){
  margin-left: 10px;
}

.Content {
  font-family: Arial, sans-serif;
  color: white;
}

.Content .Content-Intro{
  margin-top: 40px;
  font-size: x-large;
  font-weight: bold;
}

.Content .Intro{
  margin-top: 5px;
}

.projects {
  margin-top: 20px;
}

.projects ul {
  list-style-type: none;
}

.projects li {
  font-size: 1.2em;
  margin-bottom: 10px;
}

.tunnl-link{
  text-decoration: underline;
  color:rgb(205, 29, 29)
}


@media (max-width: 768px) {
  .App {
    margin-left: 10%;
    margin-right: 10%;
  }
}

@media (max-width: 480px) {
  .App {
    margin-left: 5%;
    margin-right: 5%;
  }
}